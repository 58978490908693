body {
  background-color: #F2F6FC !important;
  margin: 8px;
}

.MuiCard-root{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
  border-radius: 24px !important;
}

.MuiDataGrid-root {
  border: none !important;
}

pre{
  overflow: auto;
}